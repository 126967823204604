import React, { useState } from 'react';
import Total from "../Total/Total";
import { setCottageEquity } from "../../../../store/actions/savingsAndInvestments";
import { connect } from "react-redux";

const CottageEquityComp = ( props ) => {

    const [cottages, setCottages] = useState(props.cottages);

    function startValue() {
        let val = props.cottages[0]
        if (props.num === 2 ) {
            val = props.cottages[1]
        }

        return val || 0
    }

    const onChange = val => {
        let cottagesUpdated = [...cottages];
        if (props.num === 1 ) {
            cottagesUpdated[0] = val;
        }else {
            cottagesUpdated[1] = val;
        }
        setCottages(cottagesUpdated)
        props.setCottageEquity(cottagesUpdated)
    };

    return (
        <React.Fragment>
            <div className='sva-property-container'>
                <h3 >Cottage {props.num}</h3>
                < Total
                    type={'properties'}
                    total={startValue()}
                    title={'Total equity'}
                    onChange={onChange}
                />
            </div>
        </React.Fragment>
    )
};

function mapStateToProps(state) {
    return {
        cottages: state.savingsAndInvestments.properties.cottages
    }
}

function mapDispatchToProps(dispatch) {
    return {
        setCottageEquity : val => dispatch(setCottageEquity(val))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CottageEquityComp);